import React, { useEffect, useMemo } from "react";
import * as AdvanceManager from "../managers/AdvanceManager";
import * as ServiceManager from "../managers/ServiceManager";
import {
  Autocomplete,
  ButtonGroup,
  Card,
  CardContent,
  Container,
  debounce,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { User } from "firebase/auth";
import SatisfactionEmailRecordTable, {
  EmailRecordStatus,
} from "./SatisfactionEmailRecordTable";

export default function SatisfactionEmailPage({ user }: { user: User }) {
  let { domainId } = useParams();

  const [salesRep, setSalesRep] = React.useState<{
    id: string;
    name: string;
  } | null>(null);
  const [salesRepInput, setSalesRepInput] = React.useState<string>("");
  const [users, setUsers] = React.useState<
    { id: string; name: string }[] | null
  >(null);
  const [serviceDoc, setServiceDoc] = React.useState<any | null>(null);
  const [loadingUsers, setLoadingUsers] = React.useState<boolean>(false);
  const [status, setStatus] = React.useState<EmailRecordStatus>(
    EmailRecordStatus.PENDING
  ); // pending, sent, submitted

  const debouncedSearch = useMemo(
    () =>
      debounce(async (input) => {
        setLoadingUsers(true);
        const res = await AdvanceManager.searchUsers(domainId, input);
        setLoadingUsers(false);
        setUsers(
          res.map((u: any) => ({
            id: u.id,
            name: u.firstName + " " + u.lastName,
          }))
        );
      }, 500),
    []
  );

  useEffect(() => {
    debouncedSearch(salesRepInput);
  }, [salesRepInput]);

  useEffect(() => {
    const setUser = async () => {
      const users = await AdvanceManager.searchUsers(domainId, user.email);
      console.log(users);
      if (users && users.length > 0) {
        // Set the first user as the sales rep
        setSalesRep({
          id: users[0].id,
          name: users[0].firstName + " " + users[0].lastName,
        });
      } else {
        setSalesRep(null);
      }
    };
    setUser();
  }, [user, serviceDoc]);

  useEffect(() => {
    return ServiceManager.getServiceDocSnapshot(
      domainId,
      "mailchimp",
      setServiceDoc
    );
  }, [domainId]);

  return (
    <Container sx={{ my: 2 }}>
      <Stack spacing={2}>
        <Card>
          <CardContent>
            <Stack spacing={2}>
              {serviceDoc?.adminIds?.includes(user.uid) && (
                <>
                  <Typography variant="h5">Select Sales Rep</Typography>
                  <Autocomplete
                    value={salesRep}
                    onChange={(_, newValue) => setSalesRep(newValue)}
                    onInputChange={(_, newInputValue) =>
                      setSalesRepInput(newInputValue)
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.id === value?.id
                    }
                    renderInput={(params) => <TextField {...params} />}
                    options={users ?? []}
                    getOptionKey={(option) => option.id}
                    getOptionLabel={(option) => option.name}
                    loading={loadingUsers}
                  />
                </>
              )}
              <ToggleButtonGroup
                value={status}
                exclusive
                onChange={(_, newStatus) => {
                  if (newStatus !== null) {
                    setStatus(newStatus);
                  }
                }}
                fullWidth
              >
                <ToggleButton value={EmailRecordStatus.PENDING}>
                  Pending
                </ToggleButton>
                <ToggleButton value={EmailRecordStatus.SENT}>Sent</ToggleButton>
                <ToggleButton value={EmailRecordStatus.SUBMITTED}>
                  Submitted
                </ToggleButton>
              </ToggleButtonGroup>
            </Stack>
          </CardContent>
        </Card>
        {salesRep && (
          <SatisfactionEmailRecordTable
            status={status}
            salesRep={salesRep}
            user={user}
          />
        )}
      </Stack>
    </Container>
  );
}
