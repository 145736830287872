import { Autocomplete, Button, Card, CardContent, TextField, FormControl, InputLabel, Select, MenuItem, Stack, Typography } from "@mui/material";
import { MuiChipsInput } from "mui-chips-input";
import { useParams } from "react-router-dom";
import { doc, updateDoc, arrayUnion } from "firebase/firestore";
import React from "react";

const DEFAULT_VENDOR_SETTINGS = {
    "name": "",
    "id": "",
    "matches": [],
    "type": "Manual",
    "defaultSourceWarehouse": 0
}

function AdvanceVendorSettingsCard({ db, vendorSettings }) {
    let { domainId } = useParams();
    let [vendors, setVendors] = React.useState([]);
    let [vendor, setVendor] = React.useState({...DEFAULT_VENDOR_SETTINGS});

    React.useEffect(() => {
        setVendors(vendorSettings.map((vs, idx) => ({...vs, idx})));
    }, [vendorSettings]);

    const createNewVendor = () => {
        if (!vendor.matches.includes(vendor.name)) {
            vendor.matches.push(vendor.name);
        }
        updateDoc(doc(db, "domains", domainId, "services", "advance"), {
            vendorSettings: arrayUnion(vendor)
        });
        setVendor({...DEFAULT_VENDOR_SETTINGS});
    }

    const updateVendor = () => {
        console.log(vendors);
        updateDoc(doc(db, "domains", domainId, "services", "advance"), {
            vendorSettings: vendors.map(vs => {
                if (vs.idx === vendor.idx) {
                    let newVendor = {...vendor};
                    delete newVendor.idx;
                    return newVendor;
                }
                let newVendor = {...vs};
                delete newVendor.idx;
                return newVendor;
            })
        });
        setVendor({...DEFAULT_VENDOR_SETTINGS});
    }

    const deleteVendor = () => {
        updateDoc(doc(db, "domains", domainId, "services", "advance"), {
            vendorSettings: vendors.filter(vs => vs.idx !== vendor.idx)
        });
        setVendor({...DEFAULT_VENDOR_SETTINGS});
    }

    const handleVendorValueUpdate = (key, value) => {
        let newVendor = {...vendor, [key]: value};
        if (value === undefined) {
            delete newVendor[key];
        }
        setVendor(newVendor);
    }

    return (
        <Card>
            <CardContent>
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    gap={2}
                >
                    <Typography variant="h5">Vendor Settings</Typography>
                    <Autocomplete
                        disablePortal
                        id="vendor-select"
                        options={vendors}
                        value={vendor.idx !== undefined ? vendor : null}
                        getOptionKey={(option) => option.idx}
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option, value) => option.idx === value.idx}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Vendor" />}
                        onChange={(_, value) => {
                            console.log(value);
                            setVendor(value ?? {...DEFAULT_VENDOR_SETTINGS})
                        }}
                    />
                    <TextField
                        required
                        value={vendor.name} label="Advance Vendor Name"
                        onChange={(event) => handleVendorValueUpdate("name", event.target.value)}
                    />
                    <TextField
                        required
                        value={vendor.id} label="Advance Vendor ID"
                        onChange={(event) => handleVendorValueUpdate("id", event.target.value)}
                    />
                    <TextField
                        required
                        value={vendor.defaultSourceWarehouse?.toString() ?? "0"} label="Default Source Warehouse"
                        onChange={(event) => handleVendorValueUpdate("defaultSourceWarehouse", event.target.value === "" ? 0 : parseInt(event.target.value))}
                    />
                    <MuiChipsInput required label={"Matches"} value={vendor.matches} onChange={(value) => handleVendorValueUpdate("matches", value)} />
                    <FormControl sx={{ width: 300 }}>
                        <InputLabel id="select-type-label">Type</InputLabel>
                        <Select
                            required
                            labelId="select-type-label"
                            id="select-type"
                            value={vendor.type}
                            label="Type"
                            onChange={(event) => handleVendorValueUpdate("type", event.target.value)}
                        >
                            <MenuItem value={"Manual"}>Manual</MenuItem>
                            <MenuItem value={"PromoStandards"}>PromoStandards</MenuItem>
                            <MenuItem value={"Sage"}>Sage</MenuItem>
                            <MenuItem value={"Any"}>Any</MenuItem>
                        </Select>
                    </FormControl>
                    {
                        vendor.idx === undefined &&
                        <Button variant="contained" onClick={createNewVendor}>Create New</Button>
                    }
                    {
                        vendor.idx !== undefined &&
                        <Button variant="contained" onClick={updateVendor}>Save</Button>
                    }
                    {
                        vendor.idx !== undefined &&
                        <Button variant="outlined" color={'error'} onClick={deleteVendor}>Delete</Button>
                    }
                </Stack>
            </CardContent>
        </Card>
    )
}

export default AdvanceVendorSettingsCard;