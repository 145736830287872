import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Icon,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import * as EmailManager from "../managers/EmailManager";
import * as ServiceManager from "../managers/ServiceManager";
import { useParams } from "react-router-dom";
import {
  AdsClick,
  Check,
  CheckCircle,
  CheckOutlined,
  Clear,
  Pending,
  Visibility,
} from "@mui/icons-material";
import { User } from "firebase/auth";
import { Timestamp } from "firebase/firestore";
import { render } from "@testing-library/react";

interface EmailRecord {
  id: string;
  account: { id: number; name: string };
  contact: { id: number; name: string };
  order: { id: number; no: string };
  orderIdentity: string;
  status: EmailRecordStatus;
  events: { event: string; timestamp: Timestamp }[];
  formSubmittedAt?: Timestamp;
}

export enum EmailRecordStatus {
  PENDING = "pending",
  SENT = "sent",
  SUBMITTED = "submitted",
  CANCELED = "canceled",
}

export default function EmailRecordTable({
  salesRep,
  user,
  status,
}: {
  salesRep: { id: string; name: string };
  user: User;
  status: EmailRecordStatus;
}) {
  let { domainId } = useParams();
  const [records, setRecords] = useState<EmailRecord[] | null>(null);
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);

  const [loadingIds, setLoadingIds] = useState<string[]>([]);

  const [advanceServiceData, setAdvanceServiceData] = React.useState<{
    subdomain: string;
  } | null>(null);

  React.useEffect(() => {
    if (advanceServiceData === null) {
      ServiceManager.getServiceDocSnapshot(
        domainId,
        "advance",
        setAdvanceServiceData
      );
    }
  }, [advanceServiceData, domainId]);

  useEffect(() => {
    EmailManager.getEmailRecords(
      domainId,
      "satisfactionSurvey",
      status,
      salesRep.id,
      setRecords
    );
  }, [domainId, salesRep, status]);

  const queueEmail = (recordId: string) => async () => {
    setLoadingIds((prev) => [...prev, recordId]);
    await EmailManager.queueEmails(domainId, [recordId], user.email);
    setLoadingIds((prev) => prev.filter((id) => id !== recordId));
  };

  const queueEmails = async () => {
    const ids = rowSelectionModel.map((r) => r.toString());
    setLoadingIds((prev) => [...prev, ...ids]);
    await EmailManager.queueEmails(domainId, ids, user.email);
    setLoadingIds((prev) => prev.filter((id) => !ids.includes(id)));
    setRowSelectionModel([]);
  };

  const handleCancelRecord = (recordId: string) => () => {
    EmailManager.cancelRecord(domainId, recordId);
  };

  useEffect(() => {
    setRowSelectionModel([]);
  }, [status]);

  console.log(loadingIds);

  const columns: GridColDef<EmailRecord>[] = useMemo(
    () => [
      {
        field: "account",
        headerName: "Account",
        flex: 2,
        valueGetter: (params) => params.value.name,
      },
      {
        field: "contact",
        headerName: "Contact",
        flex: 1,
        renderCell: (params) => {
          return (
            <Tooltip title={params.value.email}>{params.value.name}</Tooltip>
          );
        },
      },
      {
        field: "order",
        headerName: "Order No",
        flex: 1,
        renderCell: (params) => {
          return (
            <Link
              href={`https://${advanceServiceData?.subdomain}.anterasaas.com/e-commerce/orders/${params.value.id}`}
              target="_blank"
              rel="noopener"
            >
              {params.value.no}
            </Link>
          );
        },
      },
      { field: "orderIdentity", headerName: "Order Identity", flex: 2 },
      ...(status === EmailRecordStatus.SENT
        ? [
            {
              field: "sentAt",
              headerName: "Sent At",
              flex: 1,
              valueGetter: ({ row }: { row: EmailRecord }) => {
                const date =
                  row.events
                    .find((e) => e.event === "send")
                    ?.timestamp?.toDate() || "";
                return date.toLocaleString();
              },
            },
            {
              field: "Events",
              headerName: "Events",
              flex: 1,
              renderCell: ({ row }: { row: EmailRecord }) => {
                const sorted = row.events.sort(
                  (a, b) => b.timestamp.toMillis() - a.timestamp.toMillis()
                );
                const send = row.events.find((e) => e.event === "send");
                const delivered = row.events.find(
                  (e) => e.event === "delivered"
                );
                const opened = row.events.find((e) => e.event === "open");
                const click = row.events.find((e) => e.event === "click");
                if (click) {
                  return (
                    <IconButton>
                      <AdsClick htmlColor="purple" />
                    </IconButton>
                  );
                } else if (opened) {
                  return (
                    <IconButton>
                      <Visibility color="success" />
                    </IconButton>
                  );
                } else if (delivered) {
                  return (
                    <IconButton>
                      <CheckCircle color="success" />
                    </IconButton>
                  );
                } else {
                  return (
                    <IconButton>
                      <Pending color="warning" />
                    </IconButton>
                  );
                }
              },
            },
          ]
        : []),
      ...(status === EmailRecordStatus.SUBMITTED
        ? [
            {
              field: "formSubmittedAt",
              headerName: "Submitted At",
              flex: 1,
              valueFormatter: ({ value }: { value: Timestamp }) =>
                value?.toDate()?.toLocaleString() ?? "",
            },
          ]
        : []),
      ...(status === EmailRecordStatus.PENDING
        ? [
            {
              field: "actions",
              headerName: "Actions",
              flex: 1,
              renderCell: ({ row }: { row: EmailRecord }) => (
                <Stack spacing={2} direction={"row"}>
                  {!loadingIds.includes(row.id) && (
                    <IconButton onClick={queueEmail(row.id)}>
                      <Check />
                    </IconButton>
                  )}
                  {loadingIds.includes(row.id) && <CircularProgress />}
                  <IconButton onClick={handleCancelRecord(row.id)}>
                    <Clear />
                  </IconButton>
                </Stack>
              ),
            },
          ]
        : []),
    ],
    [status, advanceServiceData, loadingIds]
  );

  return (
    <Card>
      {rowSelectionModel.length > 0 && (
        <Stack m={2} direction={"row"} justifyContent={"space-between"}>
          <Typography variant="subtitle1">
            {rowSelectionModel.length} record(s) selected
          </Typography>
          <Button variant="contained" color="primary" onClick={queueEmails}>
            Queue Selected
          </Button>
        </Stack>
      )}
      <Box>
        <DataGrid
          autoHeight
          columns={columns}
          rows={records?.filter((s) => s.status === status) ?? []}
          checkboxSelection={status === EmailRecordStatus.PENDING}
          onRowSelectionModelChange={setRowSelectionModel}
          rowSelectionModel={rowSelectionModel}
          getRowId={(row) => row.id}
          disableRowSelectionOnClick
        />
      </Box>
    </Card>
  );
}
