import axios from "axios";
import { getAuth } from "firebase/auth";
import {
  collection,
  doc,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { SERVICE_URL } from "../App";

function getEmailRecords(domainId, type, status, salesRepId, setRecords) {
  const db = getFirestore();
  const q = query(
    collection(db, "domains", domainId, "services", "mailchimp", "records"),
    where("type", "==", type),
    where("salesRep.id", "==", salesRepId),
    where("status", "==", status),
    orderBy("createdAt", "asc")
  );
  const unsubscribe = onSnapshot(
    q,
    (querySnapshot) => {
      const records = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setRecords(records);
    },
    (error) => console.log(error)
  );
  return unsubscribe;
}

async function queueEmails(domainId, recordIds, email) {
  const auth = getAuth();
  const token = await auth.currentUser.getIdToken();
  await axios.post(
    `${SERVICE_URL}/mailchimp/queueEmails`,
    {
      recordIds,
      email,
    },
    {
      params: {
        domainId: domainId,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

async function cancelRecord(domainId, recordId) {
  await updateDoc(
    doc(
      getFirestore(),
      "domains",
      domainId,
      "services",
      "mailchimp",
      "records",
      recordId
    ),
    {
      status: "cancelled",
    }
  );
}

export { getEmailRecords, queueEmails, cancelRecord };
